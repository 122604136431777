import React from "react"
import {
  InputBase,
  FormControl,
  InputLabel,
  Typography,
} from "@material-ui/core"
import { withStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  rootStyle: {
    width: "100%",
    display: "flex",
    marginTop: "5px",
  },
  labelStyle: {
    color: "#0A3133",
  },
}))

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2),
      width: "100%",
      color: "black",
    },
  },
  input: {
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#F5F6F8",
    border: "none",
    fontSize: "13px",
    marginBottom: "10px",
    padding: "12px 12px",
    width: "100%",
    margin: "0 10px",
    border: props => (props.error ? "1.5px solid #FD6E84 " : "none"),
    fontFamily: ["DM Sans"].join(","),
  },
}))(InputBase)

function DrawerTextField({
  placeHolder,
  label,
  type,
  onChange,
  value,
  errors,
  disable,
  customStylesForLabel = {},
  ...props
}) {
  const classes = useStyles()
  return (
    <FormControl className={classes.rootStyle}>
      <Typography
        className={classes.labelStyle}
        style={{
          marginLeft: "10px",
          color: "#0A3133",
          marginBottom: "10px",
          fontSize: "12px",
          ...customStylesForLabel,
        }}
      >
        {label}
      </Typography>
      <BootstrapInput
        disabled={disable}
        error={errors}
        value={value}
        onChange={onChange}
        id="bootstrap-input"
        type={type}
        placeholder={placeHolder}
        {...props}
      />
    </FormControl>
  )
}

export default DrawerTextField
